import axios from "axios";
import ParamsModel from "./paramsModel";

type WindowWithDataLayer = Window & {
    dataLayer?: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

class EventsTracking extends ParamsModel {
    private uuid_landing: string;
    private uuid_customer: string;
    private name_landing: string;

    constructor(
        name_landing: string,
        uuid_customer: string,
        uuid_landing: string
    ) {
        super();
        this.name_landing = name_landing;
        this.uuid_customer = uuid_customer;
        this.uuid_landing = uuid_landing;
    }

    async trackEvent(
        event: string,
        action: string,
        event_value: string,
        isGtag: boolean,
        isFB: boolean,
        email: string = null,
        phone: string = null
    ) {
        console.log({
            event: event,
            action: action,
            uuid_user: this.utils.getUUID(),
            fbp: this.utils.getCookie("_fbp"),
            fbc: this.utils.getCookie("_fbc"),
            pathname: location.pathname,
            url_search: location.search,
            name_landing: this.name_landing,
            uuid_landing: this.uuid_landing,
            event_value: event_value
          });
        const response = await axios.post(
            "https://trk.goodiewebgroup.com/tracking/insert_tracking/",
            {
                event: event,
                action: action,
                uuid_user: this.utils.getUUID(),
                fbp: this.utils.getCookie("_fbp"),
                fbc: this.utils.getCookie("_fbc"),
                pathname: location.pathname,
                url_search: location.search,
                name_landing: this.name_landing,
                uuid_landing: this.uuid_landing,
                event_value: event_value,
            }
        );

        if (isGtag) {
            await this.trackGTMEvent(event, event_value);
        }

        // if (isFB) {
        //     await this.trackFbEvent(event, email, phone);
        // }

        return response;
    }

    // async trackFbEvent(event: string, email: string, phone: string) {
    //     const response = await axios.post(
    //         "https://trk.goodiewebgroup.com/pixel",
    //         {
    //             event: event,
    //             fbc: this.utils.getCookie("_fbc"),
    //             fbp: this.utils.getCookie("_fbp"),
    //             pathName: location.pathname,
    //             email: email,
    //             phone: phone,
    //             uuid: this.utils.getUUID(),
    //             uuid_landing: this.uuid_landing,
    //         }
    //     );
    //     return response;
    // }

    async trackGTMEvent(event: string, event_value: string) {
        if (window.dataLayer) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                user_id: this.utils.getUUID(),
                event: event,
                event_value: event_value,
            });
        }
    }
}

export default EventsTracking;