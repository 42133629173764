<script lang="ts">
import { store } from "../data/store";
import BotAvatar from "../components/BotAvatar.vue";
import Checks from "../shared/checks";
import EventTracking from "../shared/eventsTracking";
import Utils from "../shared/utils";
import UserDto from "../shared/userDtoModel";

export default {
    name: "HomeView",
    components: {
        BotAvatar,
    },
    data() {
        const user: UserDto = new UserDto();
        const utils: Utils = new Utils();

        user.uuid_user = utils.getUUID();

        const eventTracking: EventTracking = new EventTracking(
            process.env.VUE_APP_NAME_LANDING,
            process.env.VUE_APP_UUID_CUSTOMER,
            process.env.VUE_APP_UUID_LANDING
        );

        return {
            store: {
                buttonsList: store.buttonsList,
            },
            loadingVisible: "loading-0",
            currentStep: 0,
            errors: [] as string[],
            user,
            eventTracking,
            uuidCustomer: process.env.VUE_APP_UUID_CUSTOMER,
            uuidLanding: process.env.VUE_APP_UUID_LANDING,
            currentTimes: [] as string[],
            dayOfWeek: new Date().getDay(),
            dataSuccess: false,
            // User Data:
            firstname: "",
            selectedInterest: "",
            contactSchedule: "",
        };
    },
    methods: {
        setTime() {
            const options: Intl.DateTimeFormatOptions = {
                hour: "numeric",
                minute: "numeric",
            };
            this.now = new Date().toLocaleString("it-IT", options);
            this.currentTimes.push(this.now);
            // console.log("Array Current Times: ", this.currentTimes);
        },
        getCurrentTime(i) {
            return this.currentTimes[i];
        },
        scrollChatDown() {
            this.$refs.chatbot.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        },
        changeStep() {
            this.currentStep++;
            this.loadNextStep(
                `loading-${this.currentStep}`,
                `message-${this.currentStep}`
            );
        },
        loadNextStep(load: string, msg: string) {
            const loadEl = this.$refs[load] as HTMLElement;
            const msgEl = this.$refs[msg] as HTMLElement;
            // console.log("currentStep: ", this.currentStep);

            if (loadEl && msgEl) {
                // Show Step Loading
                this.setTime();
                loadEl.style.display = "block";
                msgEl.style.display = "none";
                this.scrollChatDown();

                setTimeout(() => {
                    // Show Step Message
                    loadEl.style.display = "none";
                    msgEl.style.display = "block";
                    this.scrollChatDown();
                    // console.log("currentStep del setTimeout: ", this.currentStep);

                    if (this.currentStep === 1) {
                        setTimeout(() => {
                            this.changeStep();
                        }, 2000);
                    }
                }, 2000);
            }
        },
        clickAnswer(answer: string) {
            // Update User's Answer & Send GTM Event
            // console.log(this.currentStep)
            switch (this.currentStep) {
                case 0:
                    this.selectedInterest = answer;
                    this.sendCustomEvent(
                        "istitutocortivo_chat_interest",
                        answer
                    );
                    this.$refs.introButtons.style.display = "none";
                    break;
                case 2:
                    this.contactSchedule = answer;
                    this.sendCustomEvent(
                        "istitutocortivo_chat_schedule",
                        answer
                    );
                    this.$refs.scheduleButtons.style.display = "none";
                    this.validateData();
                    break;
            }

            // Show User's Chatbot Answer & Call Next Step
            const variable = `answer-${this.currentStep}` as string;
            const userAnswer = this.$refs[variable] as HTMLSelectElement;
            if (userAnswer) userAnswer.style.display = "flex";

            if (
                this.currentStep === 0 &&
                this.selectedInterest === "Si, sono interessata"
            ) {
                this.currentStep = 2;
                this.setTime();
                this.loadNextStep(
                    `loading-${this.currentStep}`,
                    `message-${this.currentStep}`
                );
            } else {
                this.changeStep();
            }
        },
        async sendCustomEvent(eventName: string, eventValue: string) {
            console.log(eventName, eventValue);

            await this.eventTracking.trackEvent(
                eventName,
                "track",
                eventValue,
                true,
                false,
                null,
                null
            );
        },
        async validateData() {
            const checks = new Checks();
            this.dataSuccess = false;
            this.errors = [];

            this.user.note = this.contactSchedule;

            if (
                this.user.note !== "" &&
                this.user.note !== undefined &&
                this.user.uuid_user !== undefined
            ) {
                // Find Lead by UUID and insert contact info
                console.log("User --> " + JSON.stringify(this.user));
                const result = await UserDto.updateLead(this.user);

                if (result === 200) {
                    console.log("inviato!");
                    console.log(result);

                    // Valid Data
                    this.dataSuccess = true;

                    // Change Route
                    setTimeout(() => {
                        this.$router.push({
                            name: "thank-you",
                            query: { schedule: this.contactSchedule },
                        });
                    }, 5000);
                }
            }
        },
    },
    async beforeMount() {
        await this.eventTracking.trackEvent(
            "istitutocortivo_chat_view",
            "track",
            null,
            true,
            false,
            null,
            null
        );
    },
    computed: {
        filteredButtonsList() {
            if (this.dayOfWeek === 5 || this.dayOfWeek === 6) {
                return this.store.buttonsList.finalStepAlt;
            } else {
                return this.store.buttonsList.finalStep;
            }
        },
    },
    mounted() {
        const { name } = this.$route.query;
        if (
            name &&
            name !== "" &&
            name !== undefined &&
            typeof name === "string"
        ) {
            this.firstname =
                name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
        }
        // Load Step 0
        this.loadNextStep("loading-0", "message-0");
    },
};
</script>

<template>
    <main class="pt-28">
        <h1
            class="text-4xl md:text-5xl lg:text-6xl text-center text-white font-bold my-8 px-2"
        >
            RICHIEDI LA BORSA DI STUDIO
        </h1>
        <div class="flex justify-center">
            <section
                ref="chatbot"
                id="chatbot"
                class="w-full max-w-[600px] mb-4 p-8"
            >
                <!-- Step 0 - Intro -->
                <div class="step-container flex items-end gap-2 w-[80%]">
                    <BotAvatar :timestamp="getCurrentTime(0)" />

                    <!-- Bot Loading -->
                    <div ref="loading-0" class="rounded-xl px-10 py-4 bg-white">
                        <div class="dot-pulse"></div>
                    </div>

                    <!-- Bot Message -->
                    <div
                        ref="message-0"
                        class="bot-message font-normal rounded-xl p-4 bg-white"
                    >
                        <p>
                            Ciao{{ firstname ? " " + firstname : null }},
                            <br />
                            hai sentito parlare della borsa di studio di 500€
                            per il corso Assistente all'Infanzia?
                        </p>
                        <div ref="introButtons" class="mt-5">
                            <template
                                v-for="item in store.buttonsList.interestedStep"
                            >
                                <button class="btn" @click="clickAnswer(item)">
                                    <span>{{ item }}</span>
                                </button>
                            </template>
                        </div>
                    </div>
                </div>

                <!-- User's Step 0 Answer -->
                <div
                    ref="answer-0"
                    class="flex justify-end mt-4 mb-8"
                    :class="{ hidden: currentStep < 1 }"
                >
                    <div
                        class="w-[40%] answer bg-[#FFD328] p-2 rounded-xl text-end"
                    >
                        <span>{{ selectedInterest }}</span>
                    </div>
                </div>

                <!-- Step 1 - Dimmi di più -->
                <div
                    v-show="
                        currentStep > 0 && selectedInterest === 'Dimmi di più'
                    "
                    class="step-container flex items-end gap-2 w-[80%]"
                >
                    <BotAvatar :timestamp="getCurrentTime(1)" />

                    <!-- Bot Loading -->
                    <div ref="loading-1" class="rounded-xl px-10 py-4 bg-white">
                        <div class="dot-pulse"></div>
                    </div>

                    <!-- Bot Message -->
                    <div
                        ref="message-1"
                        class="bot-message font-normal rounded-xl p-4 bg-white"
                    >
                        <p>
                            Permettimi di spiegarti meglio come funziona...
                            <br />
                            <br />
                            Hai la possibilità di ottenere una
                            <strong>borsa di studio del valore di 500€</strong>,
                            spendibile per l'iscrizione al corso Assistente
                            all'Infanzia.
                            <br />
                            <br />
                            E le buone notizie non sono finite!
                        </p>
                        <ul class="mt-2">
                            <li>✅ Corso 100% online</li>
                            <li>✅ Tirocinio garantito vicino a casa</li>
                            <li>✅ Studi e dai gli esami quando vuoi tu</li>
                            <li>✅ Tutor sempre disponibili</li>
                            <li>✅ Opportunità di carriera</li>
                        </ul>
                    </div>
                </div>

                <!-- Step 2 - Prenota La Chiamata -->
                <div
                    v-show="currentStep > 1"
                    class="step-container flex items-end gap-2 w-[80%]"
                    :class="{ 'mt-5': selectedInterest === 'Dimmi di più' }"
                >
                    <BotAvatar :timestamp="getCurrentTime(1)" />

                    <!-- Bot Loading -->
                    <div ref="loading-2" class="rounded-xl px-10 py-4 bg-white">
                        <div class="dot-pulse"></div>
                    </div>

                    <!-- Bot Message -->
                    <div
                        ref="message-2"
                        class="bot-message font-normal rounded-xl p-4 bg-white"
                    >
                        <p v-if="selectedInterest !== 'Dimmi di più'">
                            Benissimo! Ti contatteremo telefonicamente per darti
                            tutti le informazioni di cui hai bisogno e
                            <strong
                                >riservare una borsa di studio appositamente per
                                te</strong
                            >.
                        </p>
                        <p v-else>
                            <strong
                                >Richiedi ora, gratis, la tua borsa di
                                studio</strong
                            >
                            e ti ricontatteremo telefonicamente a breve.
                        </p>
                        <br />
                        <p>Quando preferisci essere contattata?</p>

                        <div ref="scheduleButtons" class="mt-5">
                            <template v-for="item in filteredButtonsList">
                                <button class="btn" @click="clickAnswer(item)">
                                    <span>{{ item }}</span>
                                </button>
                            </template>
                        </div>
                    </div>
                </div>

                <!-- User's Step 2 Answer -->
                <div ref="answer-2" class="hidden flex justify-end mt-4 mb-8">
                    <div
                        class="w-[40%] answer bg-[#FFD328] p-2 rounded-xl text-end"
                    >
                        <span>{{ contactSchedule }}</span>
                    </div>
                </div>

                <!-- Step 3 - Messaggio di conferma o errore -->
                <div
                    v-show="currentStep > 2"
                    class="step-container flex items-end gap-2 w-[80%]"
                >
                    <BotAvatar :timestamp="getCurrentTime(2)" />

                    <!-- Bot Loading -->
                    <div ref="loading-3" class="rounded-xl px-10 py-4 bg-white">
                        <div class="dot-pulse"></div>
                    </div>

                    <!-- Bot Message -->
                    <div
                        ref="message-3"
                        class="bot-message font-normal rounded-xl p-4 bg-white"
                    >
                        <p>
                            {{
                                dataSuccess === true
                                    ? "Grazie! Ti ricontatteremo presto!"
                                    : "Sembra che la tua richiesta di borsa di studio non sia andata a buon fine. Riprova più tardi."
                            }}
                        </p>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>

<style lang="scss" scoped>
main {
    background: linear-gradient(30deg, #7bc9f3e2, #43a5dada),
        url(../assets/main-img.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100dvh;
    overflow-y: scroll;

    & ul {
        list-style-type: none;
    }

    & .dot-pulse {
        position: relative;
        left: -9999px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #000000;
        color: #000000;
        box-shadow: 9999px 0 0 -5px;
        animation: dot-pulse 1.5s infinite linear;
        animation-delay: 0.25s;
    }

    & .dot-pulse::before,
    .dot-pulse::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #000000;
        color: #000000;
    }

    & .dot-pulse::before {
        box-shadow: 9984px 0 0 -5px;
        animation: dot-pulse-before 1.5s infinite linear;
        animation-delay: 0s;
    }

    & .dot-pulse::after {
        box-shadow: 10014px 0 0 -5px;
        animation: dot-pulse-after 1.5s infinite linear;
        animation-delay: 0.5s;
    }

    & .btn,
    .sendBtn {
        background-color: #004b80;
        width: 100%;
        color: white;
        text-align: center;
        border-radius: 0.75rem;
        padding: 0.8rem 0;
        margin: 0.3rem 0;
    }

    & .btn:hover,
    .btn:focus {
        animation: buttonScale 0.8s forwards;
    }
}

/* Media Queries */
@media screen and (max-width: 600px) {
    #chatbot {
        max-width: 330px;
        padding: 1.6rem 10px;

        & .step-container {
            width: 100%;
        }

        & .answer {
            width: 60%;
        }
    }
}

/* Animations */
@keyframes buttonScale {
    50% {
        transform: scale(0.9);
    }
}

@keyframes dot-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }

    30% {
        box-shadow: 9984px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 9984px 0 0 -5px;
    }
}

@keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }

    30% {
        box-shadow: 9999px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 9999px 0 0 -5px;
    }
}

@keyframes dot-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }

    30% {
        box-shadow: 10014px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 10014px 0 0 -5px;
    }
}
</style>
